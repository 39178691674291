import Service from "../Service";
import Vue from "vue";
const resource = "Plague/";

export default {
  save(ded, requestID) {
    return Service.post(resource + "save", ded, {
      params: { requestID: requestID },
    });
  },
  pagination(parameters, requestID) {
    return Service.post(resource + "pagination", parameters, {
      params: {
        requestID: requestID,
      },
    });
  },
  getUrlList() {
  
      return Vue.prototype.$http.defaults.baseURL + "/Plague/list";

     
  },
 };