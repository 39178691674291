import Vue from "vue";

const menu = "dinningHall/mealconsumption/";

export default {
  pagination(dtr) {
  console.log(dtr)
    return Vue.prototype.$http.post(menu + "platospersonadetail", dtr);
  },
  save(obj,usr){
    return Vue.prototype.$http.post(menu+"save",obj,{    params:{usrID:usr}});
},
  excel(dtr) {
    return Vue.prototype.$http.post(menu + "reporteplatospersonadetalle", dtr, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};
