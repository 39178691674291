<template>
  
    <s-crud 
    title="Cultivos Afectados"
    :config="config"
    @save="save($event)"
    edit
    add
    remove   
    :filter="filter"
    search-input  
    ref="crud"
         >  
        
  
   <template scope="props">
        <!--  <v-container class="pb-0"> -->
          <v-row justify="center">

            <v-col lg="4" class="s-col-form" cols="12" sm="2" md="2">
                    <s-select-definition v-model="props.item.DedID" id='DedValue' :def="1173" label="Cultivo">
                    </s-select-definition>
             </v-col>
                <v-col lg="4" class="s-col-form" cols="12" sm="2" md="2">
              <s-select-definition v-model="props.item.PcStatus" :def="1152" label="Estado"></s-select-definition>
            </v-col>                                          
               </v-row>    
          </template>
 <template v-slot:Estado="{ row }">
        <v-chip style="margin:0px" x-small :color="row.Estado == 'Inactivo' ? 'error' : 'success'">
            {{ row.Estado}}
        </v-chip>
      </template> 
     </s-crud>

</template>


<script>
import _sServices from "@/services/Technicalassistance/PlagueCultiveService.js";
import MealConsumptionReportDetailService from '../../../services/DinningHall/MealConsumptionReportDetailService';


export default {
  name: "RequirementServiceTasPaymentCategoryService",
  components: {  },
  props: {
    PlID:{ type: Number, default: 0 },
   
  },

  data() {
    return {
      dialogEdit: false,   
      filter:{PlID:0}
     };     
  },

  computed: {
   
    config() {
      return {
        model: {
          //RETORNAR PROPIEDAD COMPUTADA
          PcID: "ID",
          PlID: "int",
          DedDescription: "string",
          Estado: "string",            
          PcStatus: "status",
          //
          }, 
        service: _sServices,
        headers:  this.$fun.getSecurity().IsLevelAdmin  
          ? [
              //Vista de tabla para admin
              { text: "ID", value: "PcID", sortable: false },
              { text: "Plaga", value: "PlDescription", sortable: false },             
              { text: "Cultivo", value: "DedDescription", sortable: false }, 
              { text: "Estado", value: "Estado", sortable: false },           
            ]
          : [ 
                // CABECERA EN CASO NO SE LOGEE COMO ADMIN            
         { text: "ID", value: "PcID", sortable: false },
              { text: "Plaga", value: "PlDescription", sortable: false },             
              { text: "Cultivo", value: "DedDescription", sortable: false }, 
              { text: "Estado", value: "Estado", sortable: false },        
            ],
      };
    }, 
    
    isAdmin() {
      this.$fun.getSecurity().IsLevelAdmin;
         },  
  },
  

  methods: {

    edit(item) {
      this.dialogEdit = true;
    
    },
    save(item)
     {    
        item.PlID=this.PlID
       item.UsrCreateID=0;
       console.log(item);
       item.save();    
      
    },    
  },
  watch: {
    PlID(){         
       this.filter.PlID= this.PlID;
      },
  },
   created() {
   this.filter = { PlID: this.PlID };
  },
};
</script>
