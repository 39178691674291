<template>  
    <s-crud 
    title="Plagas"
    :config="configPlagas"
    @save="save($event)"
    edit
    add
    remove   
    :filter="filter"
    @rowSelected="rowSelected($event)"
    search-input  
    ref="crud"
    >  
    <template v-slot:filter>
    <v-container>
    <v-row justify='center'>
    <v-col lg=6>    <s-select-definition :def="1205" v-model=filter.DedID label="Tipo de Plaga" ></s-select-definition>
    </v-col>
    </v-row>

    </v-container>
    </template>
    <template scope="props">
        <v-container class=""> 
          <v-row justify="center">
             <!-- <v-col lg="4" class="s-col-form">
                <s-select-definition v-model="props.item.ZonID" label="Zona" add :def="1072" ></s-select-definition>
              </v-col>-->
             <v-col cols="12" lg=8 class="s-col-form" >
                <s-text v-model="props.item.PlDescription" label="Descripción" > </s-text>
            </v-col>     
            
               
          </v-row>  
          <v-row justify="center">
           <v-col lg="4" cols=12 class="s-col-form">
                <s-select-definition :def="1205" label="Tipo de Plaga"  v-model=props.item.DedID> </s-select-definition>
            </v-col>           
              <v-col lg="4" cols=12 class="s-col-form">
              <s-select-definition v-model="props.item.PlStatus" :def="1152" label="Estado"></s-select-definition>
            </v-col> 
          </v-row>
          </v-container>

          </template>
       <template v-slot:Estado="{ row }">
        <v-chip style="margin:0px" x-small :color="row.Estado == 'Inactivo' ? 'error' : 'success'">
            {{ row.Estado}}
        </v-chip>
      </template> 
     </s-crud>

</template>


<script>
import _sService from "@/services/Technicalassistance/TasPlagueService";



export default {
  name: "RequirementServicePlagasCultive",
  components: {  },
  props: {
       parentID: { type: Number, default: 0 },
    addNoFunction: { type: Boolean, default: false },
  },

  data() {
    return {
       plID:0,
      dialogEdit: false,   
      filter:{DedID:0},
     };     
  },

  computed: {
   
    configPlagas() {
      return {
        
        model: {
          //RETORNAR PROPIEDAD COMPUTADA
          PlID: "ID",
          PlDescription: "string",         
          DedDescription: "string", 
              PlStatus: "status",
              Estado: "string"
          //
          }, 
        service: _sService,
        headers:  this.$fun.getSecurity().IsLevelAdmin  
          ? [
              //Vista de tabla para admin
              { text: "ID", value: "PlID", sortable: false },
              { text: "Descripción", value: "PlDescription", sortable: false },
              { text: "Tipo Plaga", value: "DedDescription", sortable: false },
              { text: "Estado", value: "Estado", sortable: false }, 
                        
            ]
          : [ 
                // CABECERA EN CASO NO SE LOGEE COMO ADMIN            
            { text: "ID", value: "PlID", sortable: false },
              { text: "Descripción", value: "PlDescription", sortable: false },
              { text: "Tipo Plaga", value: "DedDescription", sortable: false },
              { text: "Estado", value: "Estado", sortable: false },         
            ],
      };
    }, 
    
    isAdmin() {
      this.$fun.getSecurity().IsLevelAdmin;
         },  
  },
  

  methods: {
    edit(item) {
      this.dialogEdit = true;
    
    },

    rowSelected(items) {
      if (items.length > 0) this.plID = items[0].PlID;
      this.$emit("rowSelected", items);
      
     },

    save(item) {     
      if (this.$fun.getSecurity().IsLevelAdmin) {   
       //VALIDACIONES
      if (item.PlDescription.length == 0) {
          this.$fun.alert("Registre la descripción", "warning");
          return;
        }
        if (item.DedID == 0) {
        this.$fun.alert("Seleccione el tipo de plaga", "warning");
        return; 
        }      
        item.UsrCreateID=0;
        item.save();
      }
       else {       
if (item.PlDescription.length == 0) {
          this.$fun.alert("Registre la descripción", "warning");
          return;
        }
        if (item.DedID == 0) {
        this.$fun.alert("Seleccione el tipo de plaga", "warning");
        return; 
        }
        item.UsrCreateID=0;
      item.save();

      } 
      
      
    },    
  },
  watch: {
    // este DedID es el que esta en variety, vista general en DedID  ="dedID"
  
  },
   created() {
 
  },
};
</script>
