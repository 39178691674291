<template>
  <div>
    <v-row>

      <v-col lg="6">
         <plagues :isAdmin="true" @rowSelected="rowSelected1($event)"  >
        </plagues>            
      </v-col>

   <v-col lg="6" v-if='plID!=0'>
         <cultive :isAdmin="true"  :PlID="plID" >
        </cultive>           
      </v-col>
      
     
    </v-row>
  </div>
</template>

<script>
    
import Plagues from '@/views/TechnicalAssistance/Plagues/Plagues.vue';
import Cultive from '@/views/TechnicalAssistance/Plagues/Cultive.vue';
export default {
  components: {
       Plagues, Cultive     
  },

   data() {
    return {
      parentID: 0,
      plID:0,
    };
  },

  methods: {
  
  rowSelected1(rows) {  if(rows.length>0){
 this.plID=rows[0].PlID;
    }
    else this.plID=0
  },
   
  },
};
</script>
