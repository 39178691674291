import Service from "../Service";

const resource = "Plaguecultive/";

export default {
  save(ded, requestID) {
    return Service.post(resource + "save", ded, {
      params: { requestID: requestID },
    });
  },
  pagination(parameters, requestID) {
    return Service.post(resource + "pagination", parameters, {
      params: {
        requestID: requestID,
      },
    });
  },
 };